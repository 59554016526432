<template>
  <div class="mobileCurriculum">
    <van-nav-bar
      :title="viewTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div
      class="video"
      :style="{
        width: fullScreen ? fullScreenHeight + 'px' : '100%',
        height: fullScreen ? fullScreenWidth + 'px' : 'auto',
      }"
    >
      <video-player
        v-if="playerOptions.sources.length"
        class="video-player vjs-custom-skin"
        :class="{ aliving: videoType == 'liveing', 'full-screen': fullScreen }"
        :style="{
          width: fullScreen ? fullScreenWidth + 'px' : '100%',
          height: fullScreen ? fullScreenHeight + 'px' : 'auto',
          top: fullScreenWidth / 2 - fullScreenHeight / 2 + 'px',
          left: -fullScreenWidth / 4 + 40 / 2 + 'px',
        }"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @playing="onPlayerPlaying($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded"
      >
      </video-player>
      <!-- @canplay="canplay($event)" -->
      <!-- @play="onPlayerPlay($event)" -->
      <!-- <div class="warning">（温馨提示：视频观看开始后不能断点续播，您需完成100%观看进度才能记录您的学习进度）</div> -->
    </div>
    <div class="video-list">
      <van-tabs>
        <van-tab title="简介">
          {{ description }}
        </van-tab>
        <van-tab title="课程目录">
          <div style="background: #f9f9f9; padding: 10px">
            <div class="video-item" v-for="(i, _i) in videos" :key="_i">
              <div class="video-title">{{ _i + 1 }}.{{ i.title }}</div>
              <div
                class="video-item-list"
                v-for="(s, _s) in i.coursewares"
                :key="_s"
                :class="{
                  active:
                    s.id == activeVideo && s.sectionId == actvieVideoSections,
                }"
                @click="viewViedo(s)"
              >
                <div class="main-title">
                  <i class="el-icon-video-camera-solid"></i>{{ s.title }}
                </div>
                <span class="timer">{{
                  formatterData(s.vedio.timeLength)
                }}</span>
                <p style="clear: both; font-size: 12px; line-height: 20px">
                  学习进度：{{ s.isDone ? "已完成" : "未完成" }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div
            class="video-item"
            v-for="(i, _i) in videos"
            :key="_i"
            @click="viewViedo(i.vedio, _i, i)"
            :class="{ active: _i == activeVideo }"
          >
            {{ i.title }}
            <span class="timer">{{ formatterData(i.vedio.timeLength) }}</span>
            <p style="font-size: 12px; line-height: 20px">学习进度：{{ i.isDone ? "已完成" : "未完成" }}</p>
          </div> -->
          <div class="empty" v-if="!videos.length">暂无课程视频</div>
        </van-tab>
        <van-tab title="视频直播">
          <div
            v-if="lives.length"
            style="margin-bottom: 20px; background: #f9f9f9; padding: 10px"
          >
            <div
              class="ailve-item"
              v-for="(i, _i) in lives"
              :key="_i"
              @click="viewIives(i, _i)"
              :class="{ active: activelives == _i }"
            >
              {{ i.living ? "【直播中】" : "【未开始】" }}{{ i.title }}
              <div class="description">{{ i.aliveDate }}</div>
            </div>
          </div>
          <!-- <div
            class="ailve-item"
            v-for="(i, _i) in lives"
            :key="_i"
            @click="viewIives(i, _i)"
            :class="{ active: i.living }"
          >
            {{ i.living ? "【直播中】" : "【未开始】" }}{{ i.title }}
            <div class="description">{{ i.aliveDate }}</div> 
          </div>-->
          <div class="empty" v-if="!lives.length">暂无直播视频</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { CommonProps } from "element-plus/lib/el-cascader-panel";
import moment from "moment";
let VideocurrentTime = 0;
export default {
  name: "curriculum",
  data() {
    return {
      httpsLocation:
        // "https://tecsf.marsontec.com",
        "https://tecsf.marsuntec.com",
      activeTabsNames: "second",
      videoType: "",
      viewTitle: "",
      description: "",
      videos: [],
      activeVideo: 0,
      lives: [],
      activelives: "",
      livesVideo: null,
      fullScreen: false,
      fullScreenWidth: document.documentElement.clientHeight,
      fullScreenHeight: document.documentElement.clientWidth,
      playerPlaying: false,
      playerOptions: {
        //播放速度
        playbackRates: [],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频·。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          // {
          //   //类型
          //   type: "video/mp4",
          //   //url地址
          //   src: "https://media.w3.org/2010/05/sintel/trailer.mp4",
          // },
        ],
        //你的封面地址
        // poster:
        // "https://gimg2.baidu.com/image_search/src=http%3A%2F%2F1812.img.pp.sohu.com.cn%2Fimages%2Fblog%2F2009%2F11%2F18%2F18%2F8%2F125b6560a6ag214.jpg&refer=http%3A%2F%2F1812.img.pp.sohu.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1624024420&t=606e426a40c26ff068470eabe257afab",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: false,
        },
        currentIndex: 0, // 默认直播第一条
        currentTime: 0, // 默认直播重头开始
      },
    };
  },
  created() {
    this.initData();
    this.windowUnload();
  },
  methods: {
    // 页面刷新或者关闭时进行数据保存
    windowUnload() {
      window.onbeforeunload = () => {
        this.setVidoData(
          {
            detailsID: this.$route.query && this.$route.query.id,
            activeVideoID: this.activeVideo,
            time: VideocurrentTime || null,
          },
          () => {},
          true
        );
      };
    },
    onClickLeft() {
      this.$router.push({
        path: "/mobileHome",
      });
    },
    onPlayerTimeupdate(player) {
      this.currentTime = player.cache_.currentTime;
      sessionStorage.setItem("videoCurrentTime", this.currentTime);
    },

    formatterData(time) {
      if (!time) {
        return "-";
      }
      const min = Math.floor(time % 3600); //分钟
      return (
        (Math.floor(time / 3600) ? Math.floor(time / 3600) + "时" : "") +
        (Math.floor(min / 60) ? Math.floor(min / 60) + "分" : "") +
        ((time % 60) + "秒")
      );
    },
    initData() {
      const id = this.$route.query && this.$route.query.id;
      this.$https.get("/api/my/course/details/" + id).then((res) => {
        if (res.success) {
          const data = res.data;
          let videoing = false;
          this.description = data.description;
          // 直播
          let _lives = data.lives.map((el) => {
            return {
              ...el,
              ...this.judeLives(res, el),
            };
          });
          this.lives = _lives
            .filter((el) => el.living)
            .concat(_lives.filter((el) => !el.living));
          this.lives.forEach((el, _el) => {
            if (el.living) {
              videoing = true;
              this.viewIives(el, _el);
            }
          });
          // 视频
          this.videos = data.courseSections.map((el) => {
            return {
              ...el,
              coursewares: data.coursewares
                .filter((item) => item.sectionId == el.id)
                .map((val) => {
                  if (!val.isDone && !videoing) {
                    videoing = true;
                    this.viewViedo(val);
                  }
                  return val;
                }),
            };
          });
          if (!videoing) {
            data.courseSections.map((el, _el) => {
              data.coursewares
                .filter((item) => item.sectionId == el.id)
                .map((val) => {
                  if (!_el) {
                    videoing = true;
                    this.viewViedo(val);
                  }
                });
            });
          }
        }
      });
    },
    onPlayerPlaying() {
      if (!this.playerPlaying) {
        this.playerPlaying = true;
        this.initFullScreem();
        this.onPlayerPlay();
        console.log("开始播放了");
        // this.$refs.videoPlayer.player.currentTime(this.currentTime);
      }
    },
    // 播放回调
    onPlayerPlay() {
      if (this.videoType == "liveing") {
        this.$refs.videoPlayer.player.currentTime(this.currentTime);
      } else {
        this.setVidoData(
          {
            detailsID: this.$route.query && this.$route.query.id,
            activeVideoID: this.activeVideo,
          },
          (list, listIndex) => {
            this.$refs.videoPlayer.player.currentTime(list[listIndex].time);
          }
        );
      }
    },
    // 暂停回调
    onPlayerPause(player) {
      this.setVidoData(
        {
          detailsID: this.$route.query && this.$route.query.id,
          activeVideoID: this.activeVideo,
          time: player.cache_.currentTime,
        },
        () => {},
        true
      );
    },
    onPlayerEnded() {
      if (this.videoType == "liveing") {
        let target = this.lives[this.activelives];
        let url =
          target.vedios.length > target.livingIndex + 1
            ? target.vedios[target.livingIndex + 1].url
            : "";
        target.livingIndex += 1;
        if (!url) this.playerOptions.notSupportedMessage = "已经结束！";
        this.playerOptions.sources = [
          {
            type:
              this.httpsLocation + url.indexOf(".m3u8")
                ? "application/x-mpegURL"
                : "video/mp4",
            src: url ? this.httpsLocation + url : "",
          },
        ];
      } else {
        this.setVidoData(
          {
            detailsID: this.$route.query && this.$route.query.id,
            activeVideoID: this.activeVideo,
            time: 0,
          },
          () => {},
          true
        );
        let data = this.videos
          .find((el) => el.id == this.actvieVideoSections)
          .coursewares.find((el) => el.id == this.activeVideo);
        if (data.isDone) return;
        this.$https
          .put(
            "/api/my/course/coursewareDone/" +
              this.$route.query.id +
              "/" +
              data.id
          )
          .then(() => {
            this.videos.forEach((el) => {
              if (el.id == this.actvieVideoSections) {
                el.coursewares.forEach((item) => {
                  if (item.id == this.activeVideo) {
                    item.isDone = true;
                  }
                });
              }
            });
          });
      }
    },
    // 直播
    viewIives(item, i) {
      this.playerPlaying = false;
      this.videoType = "liveing";
      if (!item.living) {
        this.$message({
          message: "尚未开始或已经结束",
          type: "warning",
        });
        return;
      }
      this.viewTitle = item.title;
      this.activelives = i;
      this.playerOptions.sources = [
        {
          type:
            this.httpsLocation +
            item.vedios[item.livingIndex].url.indexOf(".m3u8")
              ? "application/x-mpegURL"
              : "video/mp4",
          src: this.httpsLocation + item.vedios[item.livingIndex].url,
        },
      ];
      this.currentTime =
        item.currentTime + (moment().valueOf() - item.ajaxTime) / 1000;

      this.$https
        .put(
          "/api/my/course/liveRecord/" + this.$route.query.id + "/" + item.id
        )
        .then((res) => {
          console.log("liveRecord-mobile");
          console.log(res);
        })
        .catch((err) => {
          console.log("liveRecord-mobile-error");
          console.log(err);
        });
    },
    // 视频
    viewViedo(item) {
      this.playerPlaying = false;
      this.currentTime = 0;
      this.currentPlayTimer = 0;
      this.currentPlayStatus = false;
      this.videoType = "video";
      this.activelives = null;
      this.viewTitle = item.title;
      this.actvieVideoSections = item.sectionId;
      this.activeVideo = item.id;
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: this.httpsLocation + item.vedio.url,
        },
      ];
    },
    // 判断是否有直播数据
    judeLives(res, item) {
      let isAlive = false;
      let startTime = moment(
        moment().format("YYYY-MM-DD") + " " + item.startTime + ":00"
      ).valueOf();
      let endTime = moment(
        moment().format("YYYY-MM-DD") + " " + item.endTime + ":00"
      ).valueOf();
      if (startTime >= endTime) {
        endTime = moment(
          moment().format("YYYY-MM-DD") + " " + item.endTime + ":00"
        )
          .add(1, "d")
          .valueOf();
      }
      let aliveDate;
      // 判断日期
      Object.keys(item.dateSettings).forEach((el) => {
        let target = item.dateSettings[el];
        if (target) {
          switch (el) {
            case "monthDays":
              if (target.includes(moment(parseInt(res.stime)).format("DD"))) {
                isAlive = true;
              }
              aliveDate =
                "每月" +
                target.map((el) => el + 1).join(",") +
                "号 " +
                item.startTime +
                " 开始";
              break;
            case "specDays":
              target = target.map((el) =>
                moment(parseInt(el)).format("YYYY-MM-DD")
              );
              if (
                target.includes(
                  moment(parseInt(res.stime)).format("YYYY-MM-DD")
                )
              ) {
                isAlive = true;
              }
              aliveDate = target.join(",") + " " + item.startTime + " 开始";
              break;
            case "weeklyDays":
              if (target.includes(moment(parseInt(res.stime)).day())) {
                isAlive = true;
              }
              aliveDate =
                "每周" +
                target.map((el) => getWeekDate(el)).join(",") +
                " " +
                item.startTime +
                " 开始";
              break;
          }
        }
      });
      // 判断时间
      if (isAlive) {
        if (!(res.stime >= startTime && res.stime <= endTime)) {
          isAlive = false;
        }
      }
      // 计算直播播放时间,直播开始时间减去当前服务器时间得到已经开始的时间
      let currentTime = (res.stime - startTime) / 1000;
      let _currentTime = 0;
      let timeLength = 0;
      let livingIndex = 0;
      if (isAlive) {
        for (let i = 0; i <= item.vedios.length; i++) {
          if (timeLength >= currentTime) {
            livingIndex = i;
            _currentTime =
              item.vedios[i - 1].timeLength - (timeLength - currentTime);
            break;
          }
          timeLength += item.vedios[i].timeLength;
        }
      }
      return {
        currentTime: _currentTime,
        living: isAlive,
        // living: true,
        livingIndex: livingIndex ? livingIndex - 1 : 0,
        aliveDate: aliveDate,
        stime: res.stime,
        ajaxTime: moment().valueOf(),
      };
    },
    setVidoData(options, callBack, isSet = false) {
      // 如果是直接不保存数据
      if (this.videoType == "liveing") {
        return false;
      }
      // 保存当前数据到本地（用于保存回显视频地址和位置）
      let list = this.$storage.getStorage("vidoDataList") || [];
      // 2.929275
      let listIndex = list.findIndex(
        (p) =>
          Number(p.detailsID) === Number(options.detailsID) &&
          Number(p.activeVideoID) === Number(options.activeVideoID)
      );
      if (listIndex !== -1) {
        if (callBack) {
          callBack([...list], listIndex);
        }
        if (isSet) {
          list[listIndex] = {
            detailsID: Number(options.detailsID),
            activeVideoID: Number(options.activeVideoID),
            time: options.time != null ? options.time : list[listIndex].time,
            timeLength: options.timeLength || 0,
          };
        }
      } else {
        list.push({
          detailsID: Number(options.detailsID),
          activeVideoID: Number(options.activeVideoID),
          time: options.time || 0,
          timeLength: options.timeLength || 0,
        });
      }
      this.$storage.setStorage([
        {
          key: "vidoDataList",
          value: list,
        },
      ]);
    },
    initFullScreem() {
      this.$nextTick(() => {
        setTimeout(() => {
          const contain = document.getElementsByClassName("vjs-control-bar")[0];
          let newDom = document.createElement("div");
          newDom.setAttribute("class", "vjs-control");
          newDom.setAttribute("style", "position: absolute;right: 0;");
          newDom.innerHTML =
            '<i class="el-icon-full-screen" style="margin-top:8px;font-size: 16px;"></i>';
          contain.append(newDom);
          newDom.addEventListener("click", () => {
            this.fullScreen = !this.fullScreen;
          });
        }, 1000);
      });
    },
  },
  beforeUnmount() {
    this.setVidoData(
      {
        detailsID: this.$route.query && this.$route.query.id,
        activeVideoID: this.activeVideo,
        time: VideocurrentTime,
      },
      () => {},
      true
    );
  },
};

function getWeekDate(date) {
  const list = [
    { name: "星期日", value: 0 },
    { name: "星期一", value: 1 },
    { name: "星期二", value: 2 },
    { name: "星期三", value: 3 },
    { name: "星期四", value: 4 },
    { name: "星期五", value: 5 },
    { name: "星期六", value: 6 },
  ];
  return list.find((el) => el.value == date).name;
}
</script>
<style lang="less">
.mobileCurriculum {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  .video {
    position: relative;
  }
  @media all and (orientation: portrait) {
    .full-screen {
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      position: fixed;
      z-index: 10000;
    }
  }

  @media all and (orientation: landscape) {
    .full-screen {
      position: fixed;
      z-index: 10000;
      top: 0 !important;
      left: 0 !important;
    }
  }
  .warning {
    width: 100%;
    background: #d4d8df;
    text-align: center;
    color: #da2828;
    height: 100%;
    padding: 10px;
  }
  .video-list {
    font-size: 18px;
    .van-tab__pane {
      padding: 10px 20px;
      .video-item {
        cursor: pointer;
        line-height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-bottom: 1px solid #e4e7ed;
        font-weight: normal;
        color: #b0b9ca;
        .video-item-list {
          padding-left: 10px;
        }
        .main-title {
          width: 75%;
          float: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
        .timer {
          width: 25%;
          text-align: right;
          float: right;
        }
      }
      .ailve-item {
        cursor: pointer;
        line-height: 40px;
        border-bottom: 1px solid #e4e7ed;
        color: #b0b9ca;
        font-weight: bold;
        font-size: 18px;
        .description {
          font-size: 16px;
          font-weight: normal;
        }
      }
      .active {
        color: #0a73c5;
        .description {
          color: #677897;
        }
      }
    }
  }
}

.van-tab {
  font-size: 18px;
}

.vjs-progress-control {
  display: none !important;
}
.vjs-fullscreen-control {
  position: absolute;
  right: 0;
}
.aliving {
  .vjs-play-control {
    display: none !important;
  }
  .vjs-current-time {
    display: none !important;
  }
  .vjs-tech {
    pointer-events: none;
  }
  .timeout-lives {
    text-align: center;
  }
}
</style>
